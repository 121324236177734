.wrapper .header_wrapper{
    .anb_category {
        &_inner.ebookgnb {
            display: flex;
    
            .category {
                &_tab {
                    width: 180px;
                    li {                                
                        button {
                            font-weight: 500;
                            font-size: 14px;
                            color: #595959;
                            cursor: pointer;
    
                            &:hover {
                                color: #3a7dd3;
                            }
                        }
    
                        &.current {
                            button {
                                color: #3a7dd3;
    
                                &:after {
                                    display: inline-block;
    
                                    margin-left: 10px;
                                    width: 10px;
                                    height: 10px;
                                    background: url(../images/ink/common/arrow_gnbBtn_blue.png) no-repeat center;
                                    content: "";
                                }
                            }
                        }
                        
                        & + li {
                            margin-top: 16px;
                        }
                    }
    
                    & + .category_wrap {
                        display: flex;
                    }
                }
                
                &_wrap {
                    display: none;
                    height: calc(100vh - 435px);
                    min-height: 205px;
                    max-height: 370px;
                    overflow: auto;
                    overflow-x: hidden;
                    
                    &::-webkit-scrollbar {width: 2px;}
                    &::-webkit-scrollbar-thumb {background-color: #000;}
                    &::-webkit-scrollbar-track {background-color: #ececec;}
                }
                &_inner {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 24px 0;
                    width: 155px;
                    
                    > div {
                        .title_heading,
                        h6 {
                            margin-bottom: 17px;
                            font-weight: 600;
                            font-size: 14px;
                            color: #000;
                        }
                        .col-group {
                            display: flex;
                        }
                        .ebook-sub-area {
                            padding-top: 0;
                        }
                    }
                     
                    &.first {
                        width: 310px;
                        .category_menu{
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            align-content: flex-start;
                            gap: 10px 50px;
                            width: 276px !important;
                            overflow: hidden;
    
                            li {
                                margin-top: 0;
                                // width: 113px;
                            }
                        }
                    }
    
                }
                
                &_menu {
                    width: 115px;
                    li {
                        > a {
                            display: block;
                            width: 100%;
                            font-size: 13px;
                            color: #000;
                            padding-right: 15px;
                            background: url(../images/ink/common/ico_gnb_cat_open.png) no-repeat right center;
    
                            &.open {
                                text-decoration: underline;
                                background: url(../images/ink/common/ico_gnb_cat_close.png) no-repeat right center;
                            }
    
                            &:only-child {
                                background: none;
                            }
    
                            em {
                                display: inline-block;
                                width: 12px;
                                height: 12px;
                                background: url(../images/ink/common/ico_gnbN.png) no-repeat;
                                transform: translate(4px, -4px);
                            }
    
                        }
    
                        > div {
                            display: none;
                            margin: 10px 0 20px;
                            a {
                                display: block;
                                font-size: 13px;
                                color: #767676;
                                & + a {
                                    margin-top: 6px;
                                }
                            }
                        }
    
                        & + li {
                            margin-top: 10px;
                        }
                    }
                }
    
            }
    
        }
    
        .quick_service {
            display: flex;
            gap: 0 34px;
            margin: 40px 0 0 180px;
            justify-content: start;
            li {
                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    height: 100%;
                    span {margin: 0 auto;}
                    .title {
                        margin-top: 6px;
                        font-size: 13px;
                        color: #767676;
                    }
                    .img_box{
                        width: 70px;
                        height: 70px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            .banner_gnb{
                width: 283px;
                height: 120px;
                margin-left: auto;
                flex-shrink: 0;
                img{
                    width: 100%;
                    height: 100%; 
                    border-radius: 10px;
                }
            }
        }
    
    }
}